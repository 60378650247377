import { axios } from "./../plugins/axios";
/**
 * @method 登录接口
 */
export function Login(parameter) {
  return axios.request("", {
    method: "get",
    params: {
      module: "fans",
      action: "login",
      app: "login",
      ...parameter
    }
  });
}

/**
 * @method 注册获取验证码
 */
export function getCode(parameter) {
  return axios.request({
    method: "get",
    params: {
      module: "fans",
      action: "login",
      app: "secret_key",
      ...parameter
    }
  });
}

/**
 * @method 注册账号
 */
export function register(parameter) {
  return axios.request({
    method: "get",
    params: {
      module: "fans",
      action: "login",
      app: "register",
      ...parameter
    }
  });
}

/**
 * @method 确认密码
 */
export function subPsw(parameter) {
  return axios.request({
    method: "post",
    params: {
      module: "fans",
      action: "login",
      app: "enterPassword",
      ...parameter
    }
  });
}
/**
 * @method 微信登录授权 获取openid
 * @param {Object} parameter
 * @param in_wechat 微信中：1；微信外：0
 * @param returnUrl 回调的地址
 */
export function getOpenId(parameter) {
  return axios.request({
    method: "post",
    params: {
      module: "fans",
      action: "pay",
      app: "get_openid",
      ...parameter
    }
  });
}
/**
 * @method 微信登录授权手动点击 获取openid
 * @param {Object} parameter
 * @param code code
 * @param returnUrl 回调的地址
 */
export function wxLogin(parameter) {
  return axios.request({
    params: {
      module: "fans",
      action: "login",
      app: "authorize",
      ...parameter
    }
  });
}
/**
 *
 * @param {Object} parameter
 * @param {Number} code 验证码code
 * @param {Number} openid openid
 */
export function bindCode(parameter) {
  return axios.request({
    params: {
      module: "fans",
      action: "login",
      app: "bindCode",
      ...parameter
    }
  });
}

/**
 * @method 登录获取验证码
 */
export function getCodeList(parameter) {
  return axios.request({
    method: "POST",
    params: {
      module: "fans",
      action: "login",
      app: "secret_key",
      type: 2,
      ...parameter
    }
  });
}
// 验证码登录接口
export function LoginFromList(parameter) {
  return axios.request({
    method: "POST",
    params: {
      module: "fans",
      action: "login",
      app: "message_login",
      ...parameter
    }
  });
}

// 修改密码接口
export function LoginDate(parameter) {
  return axios.request({
    method: "POST",
    params: {
      module: "fans",
      action: "login",
      app: "secret_key",
      type: 1,
      ...parameter
    }
  });
}
// 找回密码接口
export function LoginPwd(parameter) {
  return axios.request({
    method: "POST",
    params: {
      module: "fans",
      action: "login",
      app: "find_password",
      store_id: 1,
      ...parameter
    }
  });
}
