import { axios } from "./../plugins/axios";
/**
 * @method 获取会员卡价格列表
 */
export function cardInfo() {
  return axios.request({
    params: {
      module: "fans",
      action: "card",
      app: "card_info"
    }
  });
}
/**
 * @method 购买会员卡获取支付订单号
 * @param {Object} parameter 参数对象
 * @param {Number} card_type 1-包月 2-包季 3-包年
 * @param {Number} num 数量 1 2 3
 */
export function buyCard(parameter) {
  return axios.request({
    params: {
      module: "fans",
      action: "buy",
      app: "card",
      ...parameter
    }
  });
}
/**
 * @method 支付 调用h5 支付宝网页支付
 * @param {Object} parameter
 * @param {String} type 支付类型 微信h5支付:h5_wechat/H5_wechat支付宝H5支付:h5_aplipay
 * @param {string} sNo 订单单号 buyCard 接口返回
 */
export function pay(parameter) {
  return axios.request({
    params: {
      module: "fans",
      action: "pay",
      app: "pay",
      ...parameter
    }
  });
}

/**
 * @method 支付 调用h5 微信网页支付
 * @param {Object} parameter
 * @param {String} type 支付类型 微信h5支付:h5_wechat/H5_wechat支付宝H5支付:h5_aplipay
 * @param {string} sNo 订单单号 buyCard 接口返回
 */
export function payList(parameter) {
  return axios.request({
    params: {
      module: "fans",
      action: "pay",
      app: "get_openid",
      ...parameter
    }
  });
}
