<template>
  <div>
    <van-nav-bar title="购买会员卡" left-arrow @click-left="onClickLeft" />
    <div class="userInfo">
      <!-- <div class="img"></div> -->
      <div class="info">
        <div class="infoName">
          <!-- <div class="name">七七七</div>
          <van-icon
            name="info-o"
            color="#A1A1A1"
            style="margin-left: 8px; margin-right: 6px"
            size="14px"
          /> -->
          <div class="tips">
            <div class="tipsName">购买会员卡，直接存入本账户</div>
          </div>
        </div>
        <!-- <div class="infoPhone">132****0772</div> -->
      </div>
    </div>
    <div class="centerCard">
      <div
        class="card"
        v-for="(item, index) in priceList"
        :key="index"
        :class="{ active: isActive == index }"
        @click="cardType(index)"
      >
        <div class="cardBg">{{ item.name }}</div>
        <div class="yPrice"><text class="rmb">￥</text>{{ item.price }}</div>
        <div class="price">原价￥{{ item.yPrice }}</div>
      </div>
    </div>
    <div class="cardNumber">
      <div class="name">购买数量</div>
      <div class="number">
        <!-- <u-number-box v-model="cardNum" @change="changeNum"></u-number-box> -->
        <van-stepper v-model="cardNum" @change="changeNum" />
      </div>
    </div>
    <div class="cardNumberTips">*慎重购买，一旦购买，拒不退还</div>
    <div class="footer">
      <div class="footer-centet" @click="show = true">立即购买</div>
    </div>

    <van-popup
      v-model="show"
      v-if="show"
      round
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div class="popupBox">
        <div class="popupPrice">
          <div class="popupPriceRmb">
            <text class="rmb">￥</text>{{ priceList[isActive].price * cardNum }}
          </div>
          <div class="popupPriceInfo">一号奢仓*月卡会员（{{ cardNum }}张）</div>
        </div>
        <div class="payment">
          <div class="van-hairline--top"></div>
          <div class="paymentTips">支付方式</div>
          <div class="pay">
            <div v-for="(item, index) in list" :key="index">
              <div class="payBox" v-if="showpay(item)">
                <img style="width: 28px; height: 28px" :src="item.img" />
                <div class="payName">
                  {{ item.name }}
                </div>
                <van-radio-group
                  class="check"
                  ref="RefRadioGroupChange"
                  v-model="type"
                  @change="radioGroupChange"
                >
                  <van-radio :name="item.type"></van-radio>
                </van-radio-group>
              </div>
            </div>
          </div>
          <div class="submitBtn">
            <!-- <image src="" mode=""></image> -->
            <div class="submitBtnTxt" @click="purchaseMembershipCardPayment">
              确认支付
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <div v-html="alipay"></div>
  </div>
</template>

<script>
import { getOpenId } from "@/api/login";
import { buyCard, cardInfo, pay } from "./../../api/purchaseCard";
export default {
  data() {
    return {
      cardNum: 1,
      priceList: [],
      isActive: 0,
      show: false,
      in_wechat: this.$store.state.isWeiXin,
      list: [
        {
          name: "支付宝",
          img:
            "https://t.yhaocang.com/upload/sf_static/img/purchaseCard/ali.png",
          checked: true,
          type: "h5_alipay"
        },
        {
          name: "微信支付",
          img:
            "https://t.yhaocang.com/upload/sf_static/img/purchaseCard/wechat.png",
          checked: false,
          type: "h5_wechat"
        }
      ],
      type: "h5_alipay",
      alipay: ""
    };
  },
  computed: {},
  created() {
    cardInfo().then(res => {
      console.log(res.data);
      this.priceList = res.data.data;
    });

    getOpenId({
      in_wechat: this.in_wechat,
      returnUrl: window.location.href,
      code: this.$route.query.code || ""
    }).then(res => {
      console.log(res);
      if (res.data.code == 302) {
        console.log(res.data.data, "微信回调");
        window.location.href = res.data.data.url;
      }
    });
  },
  methods: {
    showpay(e) {
      if (this.in_wechat == 1 && e.type != "h5_wechat") {
        return false;
      } else {
        return true;
      }
    },
    changeNum(e) {
      console.log(e);
    },
    cardType(index) {
      this.isActive = index;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    radioGroupChange(e) {
      console.log(e);
    },
    async purchaseMembershipCardPayment() {
      this.$toast.loading({
        message: "支付中...",
        forbidClick: true
      });

      let result = await buyCard({
        card_type: this.priceList[this.isActive].type,
        num: this.cardNum
      });
      console.log(result, "支付金额");
      if (result.data.code !== 200) {
        this.$toast(result.data.message);
        return;
      }
      // 判断用户当前点击的市那个支付
      let res = await pay({
        in_wechat: this.in_wechat,
        type: this.type,
        sNo: result.data.data.sNo,
        returnUrl: window.location.origin + "/success"
      });
      console.log(res.data, "支付返回");
      this.$toast.clear();
      if (this.in_wechat == 1 && this.type == "h5_wechat") {
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: res.data.appid, //公众号ID，由商户传入
            timeStamp: res.data.timeStamp, //时间戳，自1970年以来的秒数
            nonceStr: res.data.nonceStr, //随机串
            package: res.data.package,
            signType: res.data.signType, //微信签名方式：
            paySign: res.data.paySign //微信签名
          },
          res => {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              window.location.origin + "/success";
            }
          }
        );
        return;
      }
      if (this.type == "h5_alipay") {
        this.alipay = res.data;
        this.$nextTick(() => {
          document.getElementById("alipaysubmit").submit();
        });
      } else if (this.type == "h5_wechat") {
        window.location.href = res.data;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.van-nav-bar .van-icon {
  color: #333333;
}
.userInfo {
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 35px;
  display: flex;
  .img {
    width: 94px;
    height: 94px;
    background: #e2e2e2;
    border-radius: 50%;
  }
  .info {
    margin-left: 20px;
    .infoName {
      display: flex;
      align-items: center;
      line-height: 27px;
      .name {
        height: 27px;
        font-size: 31px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
      }
      .tips {
        // width: 290px;
        height: 33px;
        background: #fbe8bc;
        border-radius: 3px;
        padding: 4px;
        .tipsName {
          // width: 268px;
          line-height: 33px;
          font-size: 21px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #4a4343;
        }
      }
    }
    .infoPhone {
      width: 141px;
      height: 20px;
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      margin-top: 20px;
    }
  }
}
.centerCard {
  padding-left: 42px;
  padding-right: 42px;
  padding-top: 67px;
  display: flex;
  justify-content: space-between;
  .card {
    width: 206px;
    height: 296px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 9px 0px rgba(123, 123, 123, 0.27);
    border-radius: 21px;
    text-align: center;
    .cardBg {
      width: 133px;
      height: 49px;
      background: #fbc034;
      border-radius: 21px 21px 21px 0px;
      margin-top: -16px;
      margin-left: -5px;

      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
    .yPrice {
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #fbc034;
      font-size: 56px;
      margin-top: 50px;
      .rmb {
        font-size: 35px;
      }
    }
    .price {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      text-decoration: line-through;
      color: #999999;
    }
  }
}
.cardNumber {
  padding-left: 42px;
  padding-right: 42px;
  padding-top: 58px;
  display: flex;
  justify-content: space-between;
  .name {
    height: 29px;
    font-size: 31px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }
}
.cardNumberTips {
  padding-left: 44px;
  padding-top: 31px;
  height: 24px;
  font-size: 25px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
}
.footer {
  display: flex;
  justify-content: center;
  .footer-centet {
    width: 660px;
    height: 90px;
    background: #343230;
    border-radius: 45px;
    color: #ffffff;
    line-height: 90px;
    position: absolute;
    bottom: 264px;
    text-align: center;
    font-size: 38px;
  }
}
.popupBox {
  .popupPrice {
    text-align: center;
    padding-top: 58px;
    .popupPriceRmb {
      font-size: 76px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      line-height: 1;
      .rmb {
        font-size: 56px;
      }
    }
    .popupPriceInfo {
      padding-top: 30px;
      font-size: 31px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #878787;
      line-height: 1;
    }
  }
  .payment {
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 53px;
    .paymentTips {
      margin-top: 5px;
      height: 24px;
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
    .pay {
      .payBox {
        display: flex;
        align-items: center;
        padding-top: 34px;
        .payName {
          font-size: 31px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #848484;
          margin-left: 20px;
        }
      }
    }
    .submitBtn {
      position: absolute;
      bottom: 36px;
      width: 665px;
      height: 90px;
      background: #343230;
      border-radius: 45px;
      text-align: center;
      .submitBtnTxt {
        color: #ffffff;
        font-size: 34px;
        line-height: 90px;
      }
    }
  }
}
.active {
  width: 206px;
  height: 296px;
  background: #fef7e6 !important;
  border: 2px solid #fbc034 !important;
  box-shadow: 0px 1px 9px 0px rgba(123, 123, 123, 0.27);
  border-radius: 21px;
}
.check {
  width: 40px;
  height: 40px;
  margin-left: auto;
}
</style>
